<template>
  <div id="app">
    <index-show-vue></index-show-vue>
    <router-view />
  </div>
</template>
<script>
import indexShowVue from "./views/indexShow.vue";
// window.siteinfo = {
//   name: "湖南人才招聘网",
//   weixin: "湖南人才公众号",
//   city: "湖南",
//   url: "www.hnjkrc.com",
//   api: "",
//   baseURL: "https://www.hnjkrc.com/#/",
//   id: 225,
// };
export default {
  data() {
    return {};
  },
  components: {
    indexShowVue,
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
#app {
  // width: 800px;
  // height: 708px;
  // margin: auto;

  // background: pink;
  // box-shadow: 0px 0px 18px 0px rgba(203, 203, 203, 0.33);
  // border-radius: 10px;
}
li {
  list-style: none;
}
.sd > .el-textarea__inner {
  /* 去除边框 */
  border: none !important;
  resize: none !important;
  /* 去除选中后的边框 */
  outline: none !important;
}
.sd > .el-textarea__inner::-webkit-scrollbar {
  width: 0 !important;
}
.sd > .el-textarea__inner::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.sd > span {
  bottom: -32px !important;
  color: #c0c4cc !important;
  left: 12px;
  width: 10px;
}
.lunbo {
  width: 256px !important;
  margin: auto;
}
.el-carousel__indicators {
  // 指示灯位置
  left: unset !important;
  transform: unset !important;
  right: 50% !important;
  transform: translate(50%) !important;
}
.el-carousel__button {
  // 默认按钮的颜色大小等
  width: 8px !important;
  height: 8px !important;
  border: none !important;
  border-radius: 20px !important;
  background: #ccc !important;
  opacity: 1 !important;
}

.is-active .el-carousel__button {
  // 选中图片的颜色
  background: #fff !important;
  opacity: 1 !important;
}
.el-dialog__body {
  text-align: center;
}
.el-dialog {
  position: relative;
  margin: 0 auto 50px;
  background: #fff;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* display: inline-block; */
  /* width: 0px !important; */
  width: max-content !important;
  max-width: 1920px !important;
}
.sd2 > .el-textarea__inner {
  padding-left: 26px !important;
  /* 去除边框 */
  // border: none !important;
  resize: none !important;
  /* 去除选中后的边框 */
  outline: none !important;
}
.dialog_wrapper {
  min-width: 120px;
  font-size: 14px;
  line-height: 16px;
  color: #000;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
  z-index: 999999999;
  background-color: #fff;
  .fuz {
    padding: 10px 15px;
  }
  .fuz:hover {
    background-color: #f3f3f3;
  }
}
</style>
