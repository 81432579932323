<template>
  <div id="idnexService" v-show="isShows">
    <!-- 图片弹窗 -->
    <el-dialog
      :visible.sync="imgDialog"
      :before-close="handleImgClose"
      append-to-body
      top="100px"
    >
      <img style="max-width: 800px" :src="imgTcUrl" class="videoTc" alt="" />
    </el-dialog>
    <div class="chat">
      <div
        class="dialog_wrapper"
        style="position: fixed; z-index: 99"
        v-bind:style="styleObject"
        v-show="isShow"
      >
        <div class="fuz" @click="copyText">复制文字</div>
      </div>
      <div class="top" :style="style.top">
        <div class="avatarImg">
          <img class="avatar" src="../assets/images/kfimg (1).webp" alt="" />
          <h2>{{ site }}</h2>
        </div>
        <div @click="close" class="x">
          <div></div>
        </div>
      </div>
      <div class="chitchat">
        <div class="recently" @click="gochats" v-if="isLogin">
          <img
            style="margin-right: 8px"
            src="../assets/images/content.png"
            alt=""
          />
          七天内联系人
        </div>
        <div class="recently" onclick="login_qh()" v-else>
          <img
            style="margin-right: 8px"
            src="../assets/images/content.png"
            alt=""
          />
          七天内联系人
        </div>
        <div class="message gdt" ref="scrollview">
          <ul style="margin-top: 5px" @mousewheel="scrollChange">
            <div v-if="isLodding2" class="loading">
              <span></span>
            </div>
            <!-- <p class="timer" v-show="messageList.length !== 0">{{ timer }}</p> -->
            <li
              v-for="(val, index) in messageList"
              :key="index"
              :class="val.send_id == 'kf' ? 'my' : 'he'"
            >
              <div style="display: flex; align-items: center">
                <div
                  class="content messagess"
                  v-if="val.msg_type == '0'"
                  @contextmenu.prevent.stop="rightClick($event, val, index)"
                  :style="[
                    val.send_id == 'kf' ? style.kfBubble : style.userBubble,
                  ]"
                >
                  <span
                    style="
                      white-space: pre-line;
                      word-break: break-all;
                      line-break: anywhere;
                      line-height: 20px;
                    "
                    >{{ val.mes }}</span
                  >
                </div>
                <!-- 图片消息 -->
                <img
                  class="messageimg"
                  v-else-if="val.msg_type == '1'"
                  :src="imgUrl + val.mes"
                  alt=""
                  @contextmenu.prevent.stop="rightClick2($event, val, index)"
                  @click="handleMessageClick(val.mes)"
                />
              </div>
              <div v-show="val.errorMsg">
                <i
                  v-if="val.againSend"
                  class="el-icon-warning error"
                  @click="sendError(val, index)"
                ></i>
                <div v-else style="line-height: 50px" class="loading">
                  <span></span>
                </div>
              </div>

              <div v-if="val.type == 2" class="chehui_msg">{{ val.mes }}</div>
            </li>
          </ul>
        </div>
        <div class="send" v-if="isChat">
          <!-- 表情图片等 -->
          <div class="biaoqiang">
            <div class="emjoi" style="margin-right: 18px">
              <img
                class="emjoi2"
                src="../assets/images/emjoi.png"
                @click.stop="emjoiShow = !emjoiShow"
                alt=""
              />
            </div>
            <transition name="fade">
              <emjoi class="emjoi_view" v-show="emjoiShow"></emjoi>
            </transition>
            <div class="photo" @click="getFile" style="margin-right: 18px">
              <img src="../assets/images/photo.png" alt="" />
              <input
                type="file"
                ref="file"
                accept="image/*"
                style="display: none"
                v-on:change="handleFileUpload($event)"
              />
            </div>
            <div
              class="liuyan"
              @click="isQuick = !isQuick"
              style="margin-right: 18px"
            >
              <img class="liuyanimg" src="../assets/images/liuyan.png" alt="" />
            </div>
            <transition name="fade">
              <div class="fast" v-show="isQuick">
                <div
                  v-for="(val, index) in usefulExpressions"
                  :key="index"
                  class="fastmsgs"
                >
                  <span
                    class="first"
                    @click="
                      val.domains.length
                        ? showChilder(index)
                        : sendQuick(val.date)
                    "
                    style="
                      display: flex;
                      justify-content: space-between;
                      padding-right: 10px;
                    "
                    >{{ val.date }}
                    <i
                      :class="
                        val.isShow == false
                          ? 'el-icon-arrow-down'
                          : 'el-icon-arrow-up'
                      "
                      v-show="val.domains.length"
                    ></i
                  ></span>
                  <!-- <transition name="slide"> -->
                  <p
                    class="child"
                    v-show="val.isShow"
                    v-for="(val2, index2) in val.domains"
                    :key="index2"
                    @click="sendQuick(val2.value)"
                  >
                    {{ val2.value }}
                  </p>
                  <!-- </transition> -->
                </div>
              </div>
            </transition>
          </div>
          <!-- 输入框 -->
          <div class="text">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请简单描述您的问题"
              v-model="textarea"
              class="sd"
              autofocus="true"
              @keydown.native="handleKeyCode($event)"
              maxlength="200"
              show-word-limit
            >
              <!-- @keyup.enter.native="sendMessage" -->
            </el-input>
          </div>
          <div class="sendMsg">
            <el-button type="primary" :style="style.button" @click="sendMsg">
              发送
            </el-button>
          </div>
        </div>
        <div class="send" v-else>
          <!-- 表情图片等 -->
          <div class="biaoqiang">
            <div class="emjoi" style="margin-right: 18px">
              <img class="emjoi2" src="../assets/images/emjoi.png" alt="" />
            </div>
            <transition name="fade">
              <emjoi class="emjoi_view" v-show="emjoiShow"></emjoi>
            </transition>
            <div class="photo" style="margin-right: 18px">
              <img src="../assets/images/photo.png" alt="" />
              <input
                type="file"
                ref="file"
                accept="image/*"
                style="display: none"
                v-on:change="handleFileUpload($event)"
              />
            </div>
            <div class="liuyan" style="margin-right: 18px">
              <img src="../assets/images/liuyan.png" alt="" />
            </div>
            <transition name="fade">
              <div class="fast" v-show="isQuick">
                <p
                  v-for="(val, index) in usefulExpressions"
                  :key="index"
                  @click="sendQuick(val.date)"
                >
                  {{ val.date }}
                </p>
              </div>
            </transition>
          </div>
          <!-- 输入框 -->
          <div class="text">
            <p class="overChat">咨询已结束</p>
          </div>
          <div class="sendMsg">
            <el-button plain disabled> 发送 </el-button>
          </div>
        </div>
      </div>
    </div>
    <audio
      muted
      :src="playSrc"
      style="display: none"
      controls="controls"
      ref="audio"
    ></audio>
  </div>
</template>

<script>
import {
  ykDl,
  fpKf,
  bindId,
  getIp,
  joinRoom,
  sendMsg,
  saveText,
  getChatMsg,
  saveImg,
  getAutoreply,
  getVisitorList,
  getSiteTags,
  saveImgs,
  getSiteStyle,
  isUidOnline,
} from "../api/xkw";
import emjoi from "../components/emjoi.vue";
export default {
  name: "MyproIndexService",

  data() {
    return {
      isShows: true,
      playSrc: "https://chat.xuekao123.com/assets/music/messageTone.mp3",
      timer: "",
      avatarImg: "https://im.xuekao123.com/uploads/img/kfimg.webp",
      imgUrl: "https://chat.xuekao123.com",
      site: window.siteinfo.name,
      url: "", //当前站点url
      ws: null,
      wsUrl: "wss://chat.xuekao123.com/wss",
      // wsUrl: "ws://47.114.2.68:8282",
      client_id: "",
      user: {},
      ip: {},
      kefu: {},
      textarea: "",
      emjoiShow: false,
      pageSize: 1, //分页页码
      listRows: 20, //每页条数
      isLodding: true,
      isLodding2: false,
      isLodding3: true,
      isQuick: false,
      isShow: false,
      messageList: [],
      usefulExpressions: [],
      styleObject: {
        top: "0",
        left: "0",
      },
      revocationObj: {},
      isChat: true,
      // imgshow: false,
      imgDialog: false,
      imgTcUrl: "",
      firstSend: "",
      style: {
        top: {
          background: "#0362d2",
        },
        button: {
          background: "#0362D2",
          color: "#fff",
        },
        kfBubble: {
          background: "#F4F4F4",
          "--sss": "#F4F4F4",
          color: "",
        },
        userBubble: {
          background: "#0362D2",
          "--eee": "#0362D2",
          color: "#fff",
        },
      },
      errorMsg: false,
      againSend: true,
      isLogin: false,
    };
  },
  components: {
    emjoi,
  },
  beforeCreate() {
    this.bus.$on("emjois", (msg) => {
      this.textarea = this.textarea + msg;
    });
    this.bus.$on("isShows", (msg) => {
      this.isShows = msg;
    });
    this.bus.$emit("show2", true);
  },
  async created() {
    this.url = window.location.host;
    // this.url = "www.hnjkrc.com";

    let getStyleData = {
      url: this.url,
      type: 0,
    };
    let msgs = JSON.parse(sessionStorage.getItem("siteStyle"));
    if (msgs) {
      this.siteStyles(msgs);
    } else {
      let getStyle = await getSiteStyle(getStyleData);
      if (getStyle.data.code == 200) {
        let msg = getStyle.data.data;
        this.siteStyles(msg);
        // this.usefulExpressions = msg.menu.Text;
      }
    }
  },
  async mounted() {
    let isLogin = JSON.parse(localStorage.getItem("user_ID"));
    if (!isLogin) {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }

    // this.getDate();
    //获取IP地址
    // let ip = await getIp();
    this.ip = JSON.parse(sessionStorage.getItem("ip"));

    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.userInfo = JSON.parse(sessionStorage.getItem("user_ID"));
    this.kefu = JSON.parse(sessionStorage.getItem("kfId"));
    let message = JSON.parse(sessionStorage.getItem("messageList"));
    if (this.user && this.kefu) {
      this.webSocketInit();
      if (message) {
        this.messageList = message;
        this.scrollToBottom();
      } else {
        this.handlePullMessages();
      }
    } else {
      this.webSocketInit();
      this.goChat();
    }
    document.addEventListener("click", (e) => {
      // console.log(e.target.className);
      if (e.target.className !== "emjoi2" || e.target.className !== "emjoi3") {
        this.emjoiShow = false;
      }
      if (e.target.className !== "messagess") {
        this.isShow = false;
        // this.isShow2 = false;
        //   this.imgshow = false;
        // this.isResume = false;
      }
      // if (e.target.className != "liuyanimg" || e.target.className != "first") {
      //   this.isQuick = false;
      // }
    });
  },

  methods: {
    // 分配客服，查询客服是否在线
    async goChat() {
      let that = this;
      // var myId = ""; //用户i
      // 假定 随机游客ID
      // myId = parseInt(Math.random() * (9999 - 1000 + 1) + 1000);
      // let url = "baidu.com";
      // this.url = this.url;
      let datass = {
        fromUrl: this.url,
        url: this.url,
      };
      // 需要修改
      let user = await ykDl(datass);
      // console.log(user, "---------");
      if (user.data.code == 200) {
        that.user = user.data.data;
        sessionStorage.setItem("user", JSON.stringify(user.data.data));
        let service = JSON.parse(sessionStorage.getItem("kfId"));
        let datas = {};
        if (service) {
          datas = {
            client_id: this.client_id,

            message: {
              admin_id: service.data,
              type: "bind",
              myid: this.user.id,
              url: this.url,
              ip: this.ip.ip,
              ip_area: this.ip.ip_area,
            },
          };
        } else {
          datas = {
            client_id: this.client_id,
            message: {
              type: "bind",
              myid: this.user.id,
              url: this.url,
              ip: this.ip.ip,
              ip_area: this.ip.ip_area,
            },
          };
        }

        let a = await bindId(datas);

        let data = {
          url: this.url,
          toid: user.data.data.id,
        };
        let res = await fpKf(data);
        // console.log(res, "分配客服");

        if (res.data.code == 203) {
          alert("暂无客服在线，将转到留言板");
          this.$router.push("/saveLeaveMessage");
        } else {
          this.kefu = res.data.data;
          // console.log(this.kefu, "客服数据");
          this.kfRoom();
          sessionStorage.setItem("kfId", JSON.stringify(res.data.data));
        }
      }
    },
    // 链接开始聊天
    webSocketInit() {
      let that = this;
      this.ws = new WebSocket(this.wsUrl);
      // 首次链接
      this.ws.onopen = function (e) {
        // console.log(e, "链接成功");
        that.heartbeat();
      };
      // 收到消息
      this.ws.onmessage = function (e) {
        let data = JSON.parse(e.data);
        // console.log(data, "收到的信息99999999");

        if (data.type == "msg" && data.status == 2) {
          data.createtime = Math.round(new Date().getTime() / 1000).toString();
          data.send_id = "kf";
          data.mes = data.message;
          that.messageList.push(data);
          // console.log(that.messageList, "最开始的列表");
          that.scrollToBottom();
        }
        if (data.status == 2) {
          // console.log(99999999999999);
          that.play();
        }
        // init为初始化
        if (data.type == "init") {
          that.client_id = data.client_id;
          if (that.user && that.kefu) {
            that.bindId();
            // that.kfRoom();
          }
        } else if (data.type == "AdminUnsetRoom") {
          that.$message({
            message: `聊天结束`,
            type: "success",
          });
          alert("聊天已结束");
          that.isChat = false;
          window.sessionStorage.clear();
        } else if (data.type == "withdraw") {
          for (let i = 0; i < that.messageList.length; i++) {
            if (that.messageList[i].message_id == data.message_id) {
              that.messageList[i].type = 2;
              that.messageList[i].msg_type = 2;
              that.messageList[i].mes = "对方撤回一条消息";
            }
          }
        } else if (data.type == "msg" && data.status == 1) {
          data.createtime = Math.round(new Date().getTime() / 1000).toString();
          data.send_id = "user";
          data.mes = data.message;
          that.messageList.push(data);
          // console.log(that.messageList, "信息数组");
          that.scrollToBottom();
        }
      };
    },
    // 发送心跳
    heartbeat() {
      setInterval(() => {
        this.ws.send('{ "type": "ping" }');
      }, 3000);
    },
    // 绑定uid
    async bindId(cid) {
      let service = JSON.parse(sessionStorage.getItem("kfId"));
      let data = {};
      if (service) {
        data = {
          client_id: this.client_id,

          message: {
            admin_id: service.data,
            type: "bind",
            myid: this.user.id,
            url: this.url,
            ip: this.ip.ip,
            ip_area: this.ip.ip_area,
          },
        };
      } else {
        data = {
          client_id: this.client_id,
          message: {
            type: "bind",
            myid: this.user.id,
            url: this.url,
            ip: this.ip.ip,
            ip_area: this.ip.ip_area,
          },
        };
      }

      // console.log("绑定uid");
      let a = await bindId(data);
      if (a.data.code == 200) {
        this.kfRoom(cid);
      } else {
        alert("你已被拉黑");
      }
      // console.log(a, "绑定uid");
    },
    // 分配客服拉进房间
    async kfRoom(cid) {
      let roomData = {
        client_id: this.client_id,
        message: {
          type: "join",
          toid: this.kefu.data,
          myid: this.user.id,
        },
      };
      // console.log(roomData, 9999999999);
      let a = await joinRoom(roomData);
      if (a.data.code == 200) {
        if (this.$route.params.data) {
          this.sendQuick(this.$route.params.data);
        }
      }
      // console.log(a);

      // console.log("加入房间", a);
      let message = JSON.parse(sessionStorage.getItem("messageList"));
      if (message) {
        return false;
      } else {
        this.handlePullMessages();
      }
    },
    async handlePullMessages() {
      this.messageList = [];

      let data = {
        visitor_id: this.user.id,
        page: 1,
        limit: 20,
      };
      let a = await getVisitorList(data);
      if (a.data.code == 200) {
        // console.log(a, "拉取聊天记录");
        let list = a.data.data.content;
        // console.log(list);
        for (let i = 0; i < list.length; i++) {
          if (list[i].role == "admin") {
            list[i].send_id = "kf";
          }
          list[i].mes = list[i].content;
          list[i].msg_type = list[i].type;
        }
        this.messageList.push(...list);
        this.messageList = this.messageList.reverse();
        // console.log(this.messageList, "聊天获取");
        this.scrollToBottom();
      }
    },
    // 鼠标滑动
    async scrollChange() {
      let that = this;
      const e = this.$refs["scrollview"];
      const scrollTop = e.scrollTop;
      if (scrollTop < 20 && this.isLodding3) {
        that.isLodding3 = false;
        if (this.isLodding) {
          this.isLodding2 = true;
          setTimeout(async () => {
            this.isLodding = false;
            this.pageSize += 1;
            let data = {
              visitor_id: that.user.id,
              page: that.pageSize,
              limit: that.listRows,
            };
            let a = await getVisitorList(data);
            // console.log("滑动获取", a);
            if (a.data.code == 200 && a.data.data.content.length > 0) {
              let list = a.data.data.content;

              for (let i = 0; i < list.length; i++) {
                if (list[i].role == "admin") {
                  list[i].send_id = "kf";
                }
                list[i].mes = list[i].content;
                list[i].msg_type = list[i].type;
              }
              this.messageList.unshift(...list.reverse());
              // console.log(this.messageList, "聊天获取");
              const el = this.$refs["scrollview"];
              this.scrollHeight = el ? el.scrollHeight : 0;
              this.isLodding2 = false;
              setTimeout(() => {
                const currScrollHeight = el.scrollHeight;
                el.scrollTo(0, currScrollHeight - this.scrollHeight);
                this.isLodding = true;
                this.isLodding3 = true;
              }, 50);
            } else {
              this.$message({
                message: "没有更多消息了",
                type: "warning",
              });
              this.isLodding = false;
              this.isLodding2 = false;
              return false;
            }
          }, 1000);
        }
      }
    },
    // 首次加载定位到底部
    scrollToBottom() {
      setTimeout(() => {
        this.$nextTick(() => {
          var container = document.querySelector(".gdt");
          container.scrollTop = container.scrollHeight;
        });
      }, 50);
    },
    // 消息发送失败操作
    async isUidOnlines() {
      let data = {
        uid: this.kefu.data,
      };
      let a = await isUidOnline(data);
      // console.log(a, "客服是否在线");
      if (a.data.data == 0) {
        let datas = {
          client_id: this.client_id,
          message: {
            type: "bind",
            myid: this.user.id,
            url: this.url,
            ip: this.ip.ip,
            ip_area: this.ip.ip_area,
          },
        };
        // console.log("绑定uid");
        let a = await bindId(datas);
        if (a.data.code == 200) {
          let data = {
            url: this.url,
            toid: this.user.id,
          };
          let res = await fpKf(data);
          if (res.data.code == 203) {
            alert("暂无客服在线，将转到留言板");
            this.$router.push("/saveLeaveMessage");
          } else {
            this.kefu = res.data.data;
            // console.log(this.kefu, "客服数据");
            let roomData = {
              client_id: this.client_id,
              message: {
                type: "join",
                toid: this.kefu.data,
                myid: this.user.id,
              },
            };
            // console.log(roomData, 9999999999);
            let join = await joinRoom(roomData);
            if (join.data.code == 200) {
              alert("当前客服已离线，已为你重新分配客服");
              let a = {
                mes: "已为你重新分配客服",
                message: "已为你重新分配客服",
                msg_type: 2,
                send_id: "kf",
                status: 2,
                type: 2,
                userId: this.user.id,
              };
              this.messageList.push(a);
              this.$forceUpdate();
              this.scrollToBottom();
            }
          }
        }
      }
    },
    // 发送消息
    async sendMsg() {
      let that = this;
      this.textarea = this.textarea.replace(/^\s*|\s*$/g, "");
      if (this.textarea.length == 0) {
        this.$message.error("不能发送空消息");
        return false;
      }
      let data = {
        message: {
          type: "user-admin",
          toid: that.kefu.data,
          content: that.textarea,
          myid: that.user.id,
          msg_type: 0,
        },
      };
      let sendmsg = await sendMsg(data);
      if (sendmsg.data.code == 200) {
        that.textarea = "";
        this.scrollToBottom();
      } else {
        this.isUidOnlines();
        this.$message.error("对方已离线");
        let a = {
          createtime: Math.round(new Date().getTime() / 1000).toString(),
          mes: that.textarea,
          message: that.textarea,
          msg_type: "0",
          myId: that.kefu.data,
          send_id: "user",
          status: 1,
          type: "msg",
          userId: that.user.id,
          errorMsg: true,
          againSend: true,
        };
        that.messageList.push(a);
        this.scrollToBottom();
        that.textarea = "";
      }
      // console.log(sendmsg, "发送消息的回调");
    },
    // 重新发送失败消息
    async sendError(val, index) {
      let that = this;
      // console.log(this.messageList[index].errorMsg);
      // console.log(val);
      let data = {};
      if (val.msg_type == 0) {
        data = {
          message: {
            type: "user-admin",
            toid: that.kefu.data,
            content: val.mes,
            myid: that.user.id,
            msg_type: 0,
          },
        };
      } else {
        data = {
          message: {
            type: "user-admin",
            toid: this.kefu.data,
            content: val.mes,
            myid: this.user.id,
            msg_type: 1,
          },
        };
      }

      this.messageList[index].againSend = !this.messageList[index].againSend;
      setTimeout(async () => {
        let sendmsg = await sendMsg(data);
        if (sendmsg.data.code == 200) {
          this.scrollToBottom();
          this.messageList.splice(index, 1);
        } else {
          this.messageList[index].againSend = true;
          this.isUidOnlines();
        }
      }, 3000);
      // this.againSend = false;
    },
    // 常用语发送
    async sendQuick(val) {
      let that = this;
      this.isQuick = false;
      let data = {};
      if (val.title) {
        data = {
          message: {
            type: "user-admin",
            toid: that.kefu.data,
            content: val.title,
            myid: that.user.id,
            msg_type: 0,
            reply: val.content,
          },
        };
      } else {
        data = {
          message: {
            type: "user-admin",
            toid: that.kefu.data,
            content: val,
            myid: that.user.id,
            msg_type: 0,
          },
        };
      }

      let sendmsg = await sendMsg(data);
      if (sendmsg.data.code == 200) {
        this.scrollToBottom();
      } else {
        this.$message.error("对方已离线");
        let a = {};
        if (val.title) {
          a = {
            createtime: Math.round(new Date().getTime() / 1000).toString(),
            mes: val.title,
            message: val.title,
            msg_type: "0",
            myId: that.kefu.data,
            send_id: "user",
            status: 1,
            type: "msg",
            userId: that.user.id,
            errorMsg: true,
            againSend: true,
          };
        } else {
          a = {
            createtime: Math.round(new Date().getTime() / 1000).toString(),
            mes: val,
            message: val,
            msg_type: "0",
            myId: that.kefu.data,
            send_id: "user",
            status: 1,
            type: "msg",
            userId: that.user.id,
            errorMsg: true,
            againSend: true,
          };
        }

        that.messageList.push(a);
        this.isUidOnlines();
        this.scrollToBottom();
      }
    },
    // 点击自己出现
    showChilder(index) {
      let that = this;

      this.usefulExpressions[index].isShow =
        !this.usefulExpressions[index].isShow;
      this.usefulExpressions.forEach(function (val, index2) {
        if (index2 !== index) {
          that.usefulExpressions[index2].isShow = false;
        }
      });
      this.$forceUpdate();
    },
    // 发送图片
    // 获取文件
    getFile() {
      this.$refs.file.click();
    },
    // 获取文件
    async handleFileUpload(event) {
      let that = this;
      // 阻止发生默认行为
      event.preventDefault();
      let formData = new FormData();
      let file = this.$refs.file.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function () {
        // 获取Data URL的长度
        const size = reader.result.length;
        const maxSize = 1024 * 1024 * 2; // 最大允许上传1MB的图片
        if (size > maxSize) {
          that.$refs.file.value = "";
          alert("文件过大，请上传大小不超过2MB的图片！");
          return;
        } else {
          formData.append("img", file);
          formData.append("user", that.kefu.data);
          formData.append("toid", that.user.id);
          formData.append("send_id", "kf");
          // formData.append(
          //   "addtime",
          //   Math.round(new Date().getTime() / 1000).toString()
          // );
          let a = await saveImgs(formData);
          // console.log(a, 99999999999);
          if (a.data.code == 200) {
            let msg = {
              message: {
                type: "user-admin",
                toid: that.kefu.data,
                content: a.data.imgPath,
                myid: that.user.id,
                msg_type: 1,
              },
            };
            let send = await sendMsg(msg);
            // console.log(send, "发送图片");
            if (send.data.code == 206) {
              this.$message({
                type: "error",
                message: "客服不在线!",
              });
              let data = {
                createtime: Math.round(new Date().getTime() / 1000).toString(),
                mes: a.data.imgPath,
                message: a.data.imgPath,
                msg_type: "1",
                myId: that.kefu.data,
                send_id: "user",
                status: 1,
                type: "msg",
                userId: that.user.id,
                errorMsg: true,
                againSend: true,
              };
              that.messageList.push(data);
              that.isUidOnlines();
              that.scrollToBottom();
            } else {
              that.scrollToBottom();
            }
            // console.log(send);
            that.$refs.file.value = "";
          }
        }
        // 文件大小符合要求，可以进行上传操作
        // ...
      };

      // console.log(formData.get('file'))
      // this.onUpload(formData);
    },
    // 图片弹窗
    handleImgClose() {
      this.imgDialog = false;
    },
    // 获取时间
    getDate() {
      let date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      this.timer = `${hours}:${minutes}`;
    },
    // 消息右击
    rightClick(event, e, index) {
      this.isShow = !this.isShow;
      this.revocationObj = e;
      //   this.isChehui = index;
      let x = event.pageX;
      let y = event.pageY;
      this.styleObject.left = x + "px";
      this.styleObject.top = y + "px";
    },
    // 复制文字
    copyText() {
      // this.$clipboard(this.revocationObj.msg);
      this.$copyText(this.revocationObj.mes).then(
        function (e) {
          // alert("Copied");
          // console.log(e);
        },
        function (e) {
          // console.log(e);
        }
      );
    },
    // 点击图片
    handleMessageClick(val) {
      // if (message.type == "image") {
      this.imgTcUrl = this.imgUrl + val;
      this.imgDialog = true;
      // this.display();
      // }
    },
    // 回车发送
    handleKeyCode(e) {
      if ((e.which == 13 && e.ctrlKey) || (e.which == 10 && e.ctrlKey)) {
        // 这里实现换行
        this.textarea = this.textarea + "\n";
      } else if (e.which == 13) {
        // 避免回车键换行
        e.preventDefault();
        this.sendMsg();
        //执行你想要的回车操作
      }
    },
    play() {
      this.$refs.audio.play();
    },
    gochats() {
      sessionStorage.setItem("isKefu", true);

      window.open(/im/);
      localStorage.setItem("isKefu", true);

      // this.$router.push("/chat");
    },
    close() {
      this.$router.push("/");
      // this.isShows = false;
    },

    siteStyles(msg) {
      this.style.kfBubble.background = msg.chatsetting.kfbubble;
      this.style.kfBubble["--sss"] = msg.chatsetting.kfbubble;
      this.style.kfBubble.color = msg.chatsetting.kfmes;
      this.style.userBubble.background = msg.chatsetting.fkbubble;
      this.style.userBubble["--eee"] = msg.chatsetting.fkbubble;
      this.style.userBubble.color = msg.chatsetting.fkmes;
      this.style.button.background = msg.chatsetting.sendbtn;
      this.style.top.background = msg.themetxt.maincolor;

      if (msg.menu) {
        let a = msg.menu.Text;

        for (let i = 0; i < a.length; i++) {
          // console.log(a[i]);
          if (a[i].resource == 2) {
            if (a[i].email) {
              let value = {
                value: a[i].email,
              };
              a[i].domains.push(value);
            }

            // console.log(a[i]);
            this.usefulExpressions.push(a[i]);
            for (let i = 0; i < this.usefulExpressions.length; i++) {
              this.usefulExpressions[i].isShow = false;
            }
          }
        }
      }
    },
  },
  watch: {
    messageList: {
      handler(navl, oval) {
        if (navl) {
          sessionStorage.setItem(
            "messageList",
            JSON.stringify(this.messageList)
          );
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    // console.log(999);
  },
};
</script>

<style lang="scss" scoped>
#idnexService {
  position: fixed;
  bottom: 20px;
  right: 10px;
  .loading {
    text-align: center;
  }
  .loading span {
    display: inline-block;
    width: 10px;
    margin: 0 auto;
    height: 10px;
    border: 2px solid #409eff;
    border-left: transparent;
    animation: zhuan 0.5s linear infinite;
    border-radius: 50%;
  }
  @keyframes zhuan {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .chat {
    width: 350px;
    height: 600px;
    background: #f0f0f0;
    box-shadow: 0px 0px 14px 0px rgba(180, 180, 180, 0.5);
    border-radius: 10px;

    .top {
      width: 350px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #0362d2;
      padding-left: 18px;
      border-radius: 10px 10px 0 0;
      box-sizing: border-box;
      .avatarImg {
        display: flex;
        align-items: center;
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
        h2 {
          margin-left: 18px;
          font-size: 16px;
          font-family: Source Han Serif CN;
          font-weight: bold;
          color: #ffffff;
          line-height: 24px;
        }
      }
      .x {
        margin-right: 25px;
        height: 100%;
        display: flex;
        align-items: center;
        div {
          width: 20px;
          height: 2px;
          background: #ffffff;
        }
      }
    }
    .chitchat {
      width: 330px;
      height: 520px;
      background: #ffffff;
      margin: 12px auto;
      .recently {
        cursor: pointer;
        width: 330px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #f1f1f1;
        line-height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-family: Source Han Serif CN;
        font-weight: 500;
        color: #0c0c0c;
        line-height: 24px;
        box-sizing: border-box;
      }
      .message {
        max-height: 478px;
        height: 335px;
        overflow-y: scroll;
        box-sizing: border-box;

        > ul {
          // max-height: 420px;
          // height: 370px;

          padding: 0px 0px 0;
          box-sizing: border-box;
          .timer {
            font-size: 14px;
            font-family: Source Han Serif CN;
            font-weight: 500;
            color: #999999;
            line-height: 24px;
            text-align: center;
          }
        }
        li {
          margin-bottom: 20px;
          display: flex;
          position: relative;
          .avatar {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            vertical-align: middle;
          }
          .chehui_msg {
            background: #e9e9e9;
            color: #aaa;
            font-size: 12px;
            margin: 0 auto;
            padding: 5px 10px;
            border-radius: 4px;
          }
          .content {
            display: inline-block;
            max-width: 260px;
            min-height: 40px;
            padding: 8px 12px;
            box-sizing: border-box;
            background: #eef2f7;
            border-radius: 4px;
            font-size: 14px;
            font-family: Source Han Serif CN;
            font-weight: 500;
            color: #333333;
            position: relative;
            margin-left: 15px;
          }
          .messageimg {
            margin: 0 10px;
            max-width: 260px;
            max-height: 100px;
          }
          .jiaohuan {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            p {
              max-width: 260px;
              height: 38px;
              box-sizing: border-box;
              border-top: 0.5px solid #ccc;
              margin-top: 5px;
              line-height: 38px;
              display: flex;
              // justify-content: space-evenly;
              text-align: center;
              span {
                flex: 1;
                cursor: pointer;
              }
              span:nth-child(1) {
              }
              span:nth-child(2) {
                color: #0362d2;
              }
            }
          }
          .error {
            // position: absolute;

            color: red;
            line-height: 50px;
            cursor: pointer;
          }
        }
        .my {
          justify-content: flex-start;
          .content::after {
            content: "";
            position: absolute;
            // right: -100px;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            left: -7px;
            top: 10px;
            border-left: none;
            border-right-color: var(--sss);
          }
          // .error {
          //   right: 0;
          // }
          .error {
            margin-left: 10px;
          }
        }
        .he {
          // justify-content: flex-end;
          display: flex;
          flex-direction: row-reverse;
          margin-right: 12px;
          img {
            float: right;
            margin-left: 15px;
          }
          .content::after {
            content: " ";
            position: absolute;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            top: 10px;
            right: -7px;
            border-right: none;
            border-left-color: var(--eee);
          }
        }
        .jhsj {
          width: 100%;
          margin-top: 13px;
        }
      }
      .send {
        box-sizing: border-box;
        height: 143px;

        border-top: 1px solid #f1f1f1;
        .biaoqiang {
          padding: 0 15px 0;
          display: flex;
          align-items: center;
          height: 40px;
          position: relative;
          .emjoi_view {
            position: absolute;
            z-index: 99;
            left: 4px;
            bottom: 36px;
            ul {
              width: 300px;
            }
          }
          .fast {
            position: absolute;
            box-sizing: border-box;
            bottom: 36px;
            left: 90px;
            width: 200px;
            padding: 5px 0px 5px;
            background-color: #fff;
            color: #333333;
            font-weight: 500;
            font-size: 14px;
            box-shadow: 0px 0px 14px 0px rgb(180 180 180 / 50%);
            .first {
              display: block;

              cursor: pointer;
              color: #333;
              font-weight: 600;
              line-height: 22px;
              text-indent: 0.5em;
            }
            .first:hover {
              background-color: #0362d2;
              color: #fff;
            }
            .child {
              text-indent: 1em;
              font-weight: 500;
              color: #999;
              line-height: 20px;
            }
            .child:hover {
              background-color: #0362d2;
              color: #fff;
            }
          }
        }
        .sendMsg {
          text-align: right;
          padding-right: 16px;
          box-sizing: border-box;

          margin-top: 8px;
          button {
            width: 70px;
            height: 30px;
            background: #efefef;
            border-radius: 2px;
            border: none;
            font-weight: 500;
            color: #858585;
            line-height: 8px;
          }
        }
        .overChat {
          text-align: center;
          height: 50px;
          line-height: 50px;
          color: #999;
          font-size: 14px;
        }
      }
    }
    .dialog_wrapper {
      min-width: 120px;
      font-size: 14px;
      line-height: 16px;
      color: #000;
      border-radius: 6px;
      box-shadow: 0 2px 8px rgb(0 0 0 / 6%);
      z-index: 999999999;
      background-color: #fff;
      .fuz {
        padding: 10px 15px;
      }
      .fuz:hover {
        background-color: #f3f3f3;
        border-radius: 6px;
      }
    }
  }
}
// .message ::-webkit-scrollbar {
//   // width: 0 !important;
// }
// .message::-webkit-scrollbar {
//   width: 0 !important;
//   height: 0;
// }
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  background-color: #f9f9f9;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #ccc;
}
</style>